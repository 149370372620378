<template>
    <div>
      <article>
        <section>
          <div class="country_dialogs">
            <el-dialog
              :title="`Update  Room`"
              :visible.sync="isEditCountryModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="roomForm"
                  :rules="rules"
                  label-position="top"
                  ref="roomForm"
                  class="demo-roomForm2"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item
                        label="Room Name"
                        prop="roomNumber"
                      >
                        <el-input v-model="roomForm.roomNumber"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  

              <div class="row">
                <div class="col-md-12">
                  <label class="mt-3 mb-2">Room Image</label><br />
                  <el-form-item>
                    <el-upload
                      class="upload-demo"
                      drag
                      action="''"
                      :on-change="handlePreview"
                      :on-remove="handleRemove"
                      :file-list="fileLists"
                      :auto-upload="false"
                      multiple
                    >
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress
                          v-if="imageUploadingProgress != 100"
                          type="circle"
                          :color="progressColors"
                          :percentage="imageUploadingProgress"
                          :width="80"
                          :stroke-width="4"
                        ></el-progress>
                        <el-progress
                          v-else
                          type="circle"
                          :percentage="100"
                          status="success"
                          :width="80"
                          :stroke-width="4"
                        ></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-form-item>
                </div>
              </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="editRoom('roomForm')"
                    > Save Room Changes</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Acaki Lodge, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
        
        selectedFile: null,
        isUploadingFile: false,
        loadingError: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        fileLists: [],
  
        isEditCountryModalVisible: false,
        submitting: false,
        roomForm: {
          roomNumber: "",
        },
  
        rules: {
          roomNumber: [
            {
              required: true,
              message: "Room Name is required",
              trigger: "blur",
            },
          ],
        },
      };
    },
  
    props: {
      showRoomEditDialog: {
        required: true,
        type: Boolean,
      },
      roomData: {
        required: true,
        type: Object,
      },
    },
  
    watch: {
      showRoomEditDialog() {
        if (this.showRoomEditDialog === true) {
          this.isEditCountryModalVisible = true;
          this.roomForm.roomNumber = this.roomData.roomNumber;
        } else {
          this.isEditCountryModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeRoomEditDialog");
      },
      
      handlePreview(file) {
        this.fileLists = [file];
        this.selectedFile = file.raw;
        console.log(this.selectedFile);
      },
      handleRemove() {
        this.selectedFile = null;
      },
  
      async editRoom(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              this.isUploadingFile = true;
              const formData = new FormData();
              formData.append("roomNumber", this.roomForm.roomNumber);
              formData.append("roomImage", this.selectedFile);
              let response = await this.$http.patch(
                `rooms/${this.roomData.roomID}`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: (progressEvent) => {
                    this.imageUploadingProgress = +(
                      (progressEvent.loaded / progressEvent.total) *
                      100
                    ).toFixed(0);
                  },
                }
              );
              if (
                response.data.success &&
                response.data.message == "ROOM_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Room updated successfully",
                  type: "success",
                });
                this.$emit("closeRoomEditDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to update Room",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  </style>