<template>
  <div>
    <el-button type="success" 
      :disabled="$store.state.userRoles !== 'SUPER_ADMIN'" 
      round 
      @click="showCountryDialog"
    >
      Add Room
    </el-button>
    <article>
      <section>
        <div class="countries_dialogs">
          <el-dialog :title="`Add Room`" :visible.sync="addCountryModal" :destroy-on-close="true"
            :before-close="handleClose" style="text-align: left">
            <div v-loading="submitting">
              <el-form :model="roomForm" :rules="roomRules" label-position="top" ref="roomForm"
                class="demo-countryForm2">
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item label="Room Number/Name" prop="roomNumber">
                      <el-input v-model="roomForm.roomNumber"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button type="success" round @click="addRoom('roomForm')">Add Room</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2022 Acaki Lodge, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="roomForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addCountryModal: false,
      submitting: false,
      roomForm: {
        roomNumber: "",
      },
      roomRules: {
        roomNumber: [
          {
            required: true,
            message: "Room Number/Name is required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    
    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file.raw;
      console.log(this.selectedFile);
    },

    handleChange(file, fileList) {
      this.fileLists_many = fileList;
      console.log(file.raw);
      console.log(this.fileLists_many);
      this.selectedSlideFile = file.raw;
      // this.selectedSlideFiles.push(this.selectedSlideFile);
      this.selectedSlideFiles = this.fileLists_many.map((item) => {
        return item.raw;
      });
      console.log(this.selectedSlideFiles);
    },
    handleRemove() {
      this.selectedFile = null;
    },

    handleRemoveAll(file) {
      console.log(file);
    },

    handleDownload(file) {
      console.log(file);
    },

    showCountryDialog() {
      this.addCountryModal = true;
      this.roomForm.roomNumber = "";
    },
    async addRoom(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            // alert("Gwe man" + this.$store.state.userId)
            let response = await this.$http.post(`rooms`, {
              roomNumber: this.roomForm.roomNumber,
              categoryID: this.$route.params.categoryID,
              createdBy: this.$store.state.userId,
            });
            if (
              response.data.success &&
              response.data.message == "ROOM_CREATED_SUCCESSFULLY"
            ) {
              // this.$refs[formName].resetFields();
              this.$emit("re-fresh");
              this.$notify({
                title: "Success",
                message: "Room added successfully",
                type: "success",
              });
              this.addCountryModal = false;
            } else {
              // 
              // throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Add Room",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>